import YesIcon from 'assets/images/yes-icon.svg';
import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';
import Logo from 'assets/images/logo.svg';
import footerLogo from 'assets/images/footer-logo.svg'
import subscribiliLogo from 'assets/images/logo-subscribili.png'
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
  REACT_APP_APPPOINTMENT_LINK: bookAppointmentLink,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'enabledental',
  tokenName: 'enabledentalSubscriberToken',
  globalClass: 'enabledental-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  heroLocaleColor: 'secondary',
  headerProps: {
    enable: true,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        to: '/login',
        color: 'primary',
      },
      background: '/images/home-images/home-banner-img.webp',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      alignTitle: 'center',
      alignCard: 'center',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
    },
    howItWorksSection: {
      variant: 2,
      alignTitle: 'left',
      heroImage: '/images/home-images/how-it-work.webp',
    },
    testimonialSection: {
      variant: 2,
      centerMode: true,
      avatarStyle: 'top',
      subtitleVariant: 'body1',
      sectionClass: 'section-container',
      messageType: 'h6',
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
      primaryButtonProps: {
        color: 'primary',
      },
    },
  },
  checkoutSection: {
    intialfrequency: 'annual',
    showCreateSubscriptionFor: true,
    isHipaaConsentformRequired: true,
    consentFormLink: 'https://hipaa.jotform.com/form/241224026036039',
  },
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      link: bookAppointmentLink,
      target: '_blank',
    },
  },
  savingsSummary: {
    enableFooterbar: true,
    contactSection: 'contactDetails',
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
    moreSavingsButtonProps: {
      variant: 'outlined',
      color: 'primary',
    },
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {},
  footer: {
    enable: true,
    logo: footerLogo,
    subscribiliLogo: subscribiliLogo
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
